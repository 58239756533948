<template>
  <section class="no-select">
    <template v-if="isReady && !isBillingInfoRequired">
      <UserPlanPricing
        :payload="payload"
        :busy="busy"
        @requestPlanChange="$emit('requestPlanChange', $event)"
        @upgrade="onUpgrade"
        @forceEndTest="onForceEndTest"
      />
    </template>
    <template v-if="isReady && isBillingInfoRequired">
      <UserPlanBillingInformation
        :payload="payload"
        :component_busy="busy"
        @hide-billing-information="onHideBillingInformation"
        @finish-contracting="onFinishContracting"
      />
    </template>
  </section>
</template>

<script>
import Pricing from "@/assets/dashboard/pricing.json";
import ContractService from "@/services/contract.js";
import MixinAlert from "@/project/mixin-alert.js";
import UserPlanPricing from "@/components/user-plan/user-plan-pricing.vue";
import UserPlanBillingInformation from "@/components/user-plan/user-plan-billing-information.vue";

import { contractStateConst, contractPlanConst } from "@/assets/constants.js";

export default {
  name: "UserPlanUpgradeB",
  mixins: [MixinAlert],
  components: {
    UserPlanPricing,
    UserPlanBillingInformation
  },
  props: {},
  data() {
    return {
      payload: {},
      busy: false,
      isBillingInfoRequired: false,
      force_end_test: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    isTest() {
      return this?.contract?.contract_state?.id == contractStateConst.TEST;
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    plan() {
      return ((this?.plans || []).find(
        (item) => (
          item.name.toLowerCase() == this?.contract?.contract_plan?.name.toLowerCase()
        )
      ) || {});
    },
    contractPlanIndex() {
      return (this.plans || []).findIndex(
        ({ id }) => id == (this?.contract?.contract_plan?.id || -1)
      );
    },
    selectedPlanIndex() {
      return (this.plans || []).findIndex(
        ({ id }) => id == (this?.payload?.plan_identity_number || -1)
      );
    },
    isPlanChanged() {
      let originalPlan = [
        this.contractPlanIndex,  
        this?.contract?.is_yearly,
        this?.contract?.maximum_data
      ];
      let newPlan = [
        this.selectedPlanIndex,
        this?.payload?.is_yearly,
        this?.payload?.plan_data_range,
      ];
      return originalPlan != newPlan;
    },
    resources() {
      return (
        (this.contract &&
          this.contract.billing &&
          this.contract.billing.resources) ||
        null
      );
    },
    isReady() {
      return this.resources != null;
    },
    usesPaymentGateway() {
      if (this.isTest && !this.force_end_test) return false;
      return this.$root?.config?.references?.payment_gateway;
    },
    plans() {
      return JSON.parse(
        JSON.stringify(
          this.contract.is_white_label ? Pricing.whitelabel : Pricing.regular
        )
      );
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    onHideBillingInformation() {
      this.isBillingInfoRequired = false;
    },
    upgradeText() {
      let planName = this.plans[this.selectedPlanIndex].title;
      return `${planName} - ${this.$t("up_to")} ${this.payload.plan_data_range}
        ${this.$tc("data", 2)}`;
    },
    onForceEndTest(value) {
      this.force_end_test = value;
    },
    onUpgrade(payload) {
      if (!payload) return;
      if (!this.isPlanChanged);

      let self = this;
      this.payload = payload;

      if (!this.isDemo) {
        this.payload.id = (self.contract && self.contract.id) || "";
        this.payload.etag = (self.contract && self.contract.etag) || "";
      }

      // Se não for teste e utilizar integração com método de pagamento,
      // solicita dados de cobrança
      if (self.usesPaymentGateway) {
        self.isBillingInfoRequired = true;
      } else {
        self.onFinishContracting();
      }
    },
    onFinishContracting() {
      this.busy = true;

      let swal_msg = {
        title: this.upgradeText(),
        text: this.$t("do_you_really_want_to_upgrade_your_current_plan"),
        icon: this.plans[this.selectedPlanIndex].image,
        imageSize: "32x32",
        buttons: [this.$t("cancel"), this.$t("yes_im_sure")]
      }

      // Se for migração de plano pago para gratuito (FREE)
      if (
        this.contract?.contract_plan?.id != contractPlanConst.FREE &&
        this.payload.plan_identity_number == contractPlanConst.FREE
      ) {
        this.payload.allow_downgrade_resources = true;

        swal_msg = {
          title: this.upgradeText(),
          text: this.$t("do_you_really_want_to_downgrade_your_current_plan_to_free"),
          icon: this.plans[this.selectedPlanIndex].image,
          imageSize: "32x32",
          buttons: [this.$t("cancel"), this.$t("yes_im_sure")]
        }
      } else {
        this.payload.allow_downgrade_resources = false;
      }

      // Mensagem de confirmação antes de iniciar as gravações
      this.$swal(swal_msg).then(this.confirmedContracting);
    },
    confirmedContracting(isConfirm) {
      if (!isConfirm) {
        this.busy = false;
        return;
      }

      // Se não for teste e utilizar integração com método de pagamento,
      // faz o processo completo de cobrança
      if (this.usesPaymentGateway) {
        // Finalizar atualização do contrato
        // Salvar alterações do Perfil do usuário
        let profile_payload = {
          user_profile: {
            company_name: this.payload.company_name,
            cpf_cnpj: this.payload.cpf_cnpj,
            phone: this.payload.phone,
            zipcode: this.payload.zipcode,
            number: this.payload.number,
            complement: this.payload.complement,
            email_billing: this.payload.email_billing
          }
        };
        
        this.$store.dispatch("user/updateProfile", profile_payload)
          .then(() => {
            // Finalizar atualização do contrato
            this.finishContracting().then(()=>(this.busy = false));
          })
          .catch((err) => {
            // Erro ao salvar o Perfil
            this.$swal({
              title: this.$t("item_could_not_be_saved"),
              text: err.message ? err.message : this.$t("your_plan_could_not_be_updated"),
              icon: "error"
            });
            this.busy = false;
          });
      } else {
        // Não é teste ou não tem integração com método de pagamento,
        // apenas atualiza o contrato
        this.finishContracting().then(()=>(this.busy = false));
      }
    },
    finishContracting() {
      // Fazer o checkout transparente
      return new Promise((resolve, reject) => {
        this.service
          .updateContract(this.payload)
          .then((result) => {
            resolve(result);
            // Sucesso ao realizar checkout
            this.$swal({
                title: this.$t("completed"),
                text: this.$t("your_plan_was_updated"),
                icon: "success"
              })
              .then(() => {
                document.location.reload();
              });
          })
          .catch((err) => {
            reject(err);
            // Erro ao realizar o checkout
            this.$swal({
              title: this.$t("item_could_not_be_saved"),
              text: err.message ? err.message : this.$t("your_plan_could_not_be_updated"),
              icon: "error"
            });

            this.busy = false;
          });
      });
    }
  },
  created() {
    this.service = new ContractService();
  },
  mounted() {
    this.payload = {
      plan_identity_number: this?.plan?.id,
      is_yearly: this?.contract?.is_yearly,
      plan_data_range: this?.contract?.maximum_data
    }
  }
}
</script>