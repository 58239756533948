import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    error_labels: pt
  },
  en: {
    error_labels: en
  }
}